import { render, staticRenderFns } from "./ModalWidget.vue?vue&type=template&id=3b6c2b9b&scoped=true&"
import script from "./ModalWidget.vue?vue&type=script&lang=js&"
export * from "./ModalWidget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b6c2b9b",
  null
  
)

export default component.exports
<template>
  <modal name="modalWidget" height="auto" width="900" :lazy="true" :scrollable="true" @before-open="validation.reset()" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <div class="xrow">
          <ul class="tabs">
            <li class="tab-active">
              <a @click="() => {}">{{ modalTitle }}</a>
            </li>
          </ul>
        </div>
        <div class="form">
          <div class="alert remover" v-show="!!errorMessage">
            <a href="#" class="close" @click="errorMessage = null">&times;</a>
            Falha ao salvar:
            <p>{{ errorMessage }}</p>
          </div>
          <div class="xrow">
            <div class="col-1-3">
              <div class="form-group">
                <label>Titulo</label>
                <input class="form-control" v-model="widget.titulo" maxlength="255"/>
                <div class="message">{{ validation.firstError('widget.titulo') }}</div>
              </div>
            </div>
            <div class="col-2-3">
              <div class="form-group">
                <label>Descrição</label>
                <input class="form-control" v-model="widget.descricao" maxlength="255"/>
                <div class="message">{{ validation.firstError('widget.descricao') }}</div>
              </div>
            </div>
          </div>
          <div class="xrow">
            <div class="col-1-1">
              <div class="form-group">
                <label>Perfis *</label> <span class="message" v-if="validation.firstError('widget.perfis')">{{ validation.firstError('widget.perfis') }}</span>
                <div v-for="tipo in tiposPerfis">
                  <label class="checkbox">
                    <input type="checkbox" :value="tipo.id" v-bind:checked="perfilChecked(tipo.id)" v-on:change="handlePerfilChecked(tipo.id)">
                    {{ tipo.descricao}}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="xrow">
            <div class="col-1-2">
              <div class="form-group">
                <label>Exibe Título</label>
                <select class="form-control" v-model="widget.exibeTitulo">
                  <option :value="true">Sim</option>
                  <option :value="false">Não</option>
                </select>
              </div>
            </div>
            <div class="col-1-2">
              <div class="form-group">
                <label>Tipo</label>
                <select class="form-control" v-model="widget.tipo" v-on:change="handleChangeTipo">
                  <option value="CONTEUDO">Conteúdo</option>
                  <option value="IFRAME">Iframe</option>
                </select>
              </div>
            </div>
            <div class="col-1-1">
              <div class="form-group" v-if="widget.tipo === 'CONTEUDO'" >
                <label>Conteúdo</label>
                <vue-editor 
                        class="vue2-editor"
                        v-model="widget.estrutura" 
                        :editor-toolbar="customToolbar" 
                      ></vue-editor>
                <!-- <ckeditor type="classic" v-model="widget.estrutura" :config="editorConfig" :editor="editor" /> -->
                <div class="message">{{ validation.firstError('widget.estrutura') }}</div>
              </div>
              <div class="form-group" v-if="widget.tipo === 'IFRAME'">
                <label>Url</label>
                <input class="form-control" v-model="widget.estrutura" />
                <div class="message">{{ validation.firstError('widget.estrutura') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }" v-on:click="handleSubmit()">
        Salvar
      </button>
      <button type="button" class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>

<script>

import { VueEditor } from "vue2-editor";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/pt-br.js";
import SimpleVueValidation from "simple-vue-validator";
import { find, filter } from "lodash";
import { toggleValue } from "../../../../helpers/utils";
import CloseModal from "../../../../components/CloseModal";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "Campo de preenchimento obrigatório",
  },
});

export default {
  name: "ModalWidget",
  props: ["widget", "tiposPerfis", "save"],
  components: {
    CloseModal,
    VueEditor,
  },
  data() {
    return {
      errorMessage: "",
      editor: ClassicEditor,
      editorConfig: {
        language: "pt-br",
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link", "image", "video", "code"],
        ["clean"],
      ],
    };
  },
  computed: {
    modalTitle() {
      return this.widget.id ? "Editar Widget" : "Novo Widget";
    },
    perfisSelecionados() {
      return filter(this.tiposPerfis, (tipo) => {
        return !!find(this.widget.perfis, (wP) => `${wP}` === `${tipo.id}`);
      });
    },
  },
  validators: {
    "widget.titulo": (value) => Validator.value(value).required(),
    "widget.descricao": (value) => Validator.value(value).required(),
    "widget.perfis": (value) => Validator.value(value).required(),
    "widget.estrutura": (value) => Validator.value(value).required(),
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.save(this.widget);
        }
      });
    },
    close() {
      this.$modal.hide("modalWidget");
    },
    perfilChecked(id) {
      return !!find(this.perfisSelecionados, { id: id });
    },
    handleChangeTipo() {
      this.widget.estrutura = "";
    },
    handlePerfilChecked(id) {
      this.widget.perfis = toggleValue(this.widget.perfis, id);
    },
  },
};
</script>

<style scoped>
</style>
